.video-play {
	position: relative;
	min-height: 320px;
	&::after {
		content: "";
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
	}
}

.wrapper-banners {
	position: relative;
	width: 100%;
	margin-top: 135px;
	height: 950px; 
	overflow: hidden;
	@include respond-to('huge') {
		height: 850px;
	}
	@include respond-to('larger') {
		height: 750px;
	}
	@include respond-to('large') {
		height: 650px;
	}
	@include respond-to('medium') {
		// min-height: 550px;
		height: 550px;
		margin-top: 65px;
	}
	@include respond-to('small') {
		height: 330px; //480px
	}
}

.wrap-banner-scroll {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	@include respond-to('medium') {
		position: static;
	}
}

.banner-item {
	width: 100%;
	min-height: 950px;
	position: relative;
	background-repeat: no-repeat;
	background-position: center center;
	background-size: contain; 
	@include respond-to('huge') {
		min-height: 850px;
	}
	@include respond-to('larger') {
		min-height: 750px;
	}
	@include respond-to('large') {
		min-height: 650px;
	}
	@include respond-to('medium') {
		min-height: 550px;
	}
	@include respond-to('small') {
		min-height: 330px; // 480
	}
}

.class-video {
	@include respond-to('medium') {
		min-height: unset;
	}
}

.banner-text {
	position: absolute;
	bottom: 30%;
	#{$right}: 16%;
	max-height: 400px;
	overflow: hidden;
	@include respond-to('huge') {
		top: 35%;
	}
	@include respond-to('larger') {
		top: 30%;
	}
	@include respond-to('medium') {
		top: 20%;
		padding-#{$left}: 10px;
	}
	@include respond-to('small') {
		position: static;
		top: auto;
		#{$right}: auto;
		transform: none;
		padding-bottom: 30px;
		padding-top: 15px;
		padding-#{$left}: 0;
		text-align: center;
		@include respond-to('extra-small') {
			padding-top: 0;
		}
	}
	&__title {
		max-width: 350px;
		width: 100%;
		font-size: $font-size-title;
		font-weight: 400;
		color: $accent;
		line-height: 1;
		@include respond-to('small') {
			margin: 5px auto 0 !important;
			text-align: center;
			font-size: 22px;
		}

	}
	&__description {
		max-height: 115px;
		overflow: hidden;
		max-width: 300px;
		width: 100%;
		margin-top: 20px;
		font-size: $font-size-base;
		font-weight: 300;
		color: $accent;
		line-height: 1.2;
		@include respond-to('small') {
			margin: 5px auto !important;
			text-align: center;
			font-size: 16px;
		}
	}
	&__link {
		display: inline-block;
		margin-top: 50px;
		padding-top: 10px;
		border-top: 2px solid $accent;
		font-size: $font-size-base;
		font-weight: 300;
		color: $accent;
		@include link_no-hover($accent);
		@include respond-to('larger') {
			margin-top: 25px;
		}
		@include respond-to('small') {
			margin: 5px auto 0 !important;
			padding-top: 0 !important;
			border-top-width: 1px !important;
			font-size: 16px;
		}
	}
}

.player__volume-btn {
	position: absolute;
	bottom: 150px;
	#{$left}: 30px;
	color: $accent;
	display: inline-block;
	width: 50px;
	height: 50px;
	z-index: 999;
	@include respond-to('medium') {
		#{$left}: 15px;
		bottom: 40px;
	}
	@include respond-to('extra-small') {
		bottom: 20px;
	}
	& i {
		font-size: 42px;
	}
}

// #hp-banners,
// .hp-gallery__slider {
// 	& .slick-dots {
// 		position: absolute;
// 		bottom: 22%;
// 		#{$right}: 16%;
// 		@include respond-to('huge') {
// 			bottom: 20%;
// 			#{$right}: 6%;
// 		}
// 		@include respond-to('small') {
// 			bottom: 5px;
// 			#{$right}: 0%;
// 		}
// 		& li {
// 			margin: 0 2px;
// 			padding: 0;
// 			height: 22px;
// 			& button {
// 				padding: 0;
// 				&::before {
// 					content: '';
// 					top: 50%;
// 					left: 50%;
// 					transform: translate(-50%, -50%);
// 					width: 12px;
// 					height: 12px;
// 					border: 1px solid $accent;
// 					border-radius: 50%;
// 					opacity: 1;
// 				}
// 			}
// 		}
// 		& li.slick-active {
// 			& button {
// 				&::before {
// 					border: 1px solid $accent;
// 					background-color: $accent;
// 					opacity: 1;
// 				}
// 			}
// 		}
// 	}
// }